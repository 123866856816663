import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Face from './inline/Face.svg';
import Play from './inline/PlayIcon.svg';
import Anker from './inline/logoAnker.svg';
import August from './inline/logoAugust.svg';
import Chamberlain from './inline/logoChamberlain.svg';
import Happysocks from './inline/logoHappySocks.svg';
import Liftmaster from './inline/logoLiftMaster.svg';
import Lovehoney from './inline/logoLovehoney.svg';
import Pipedrive from './inline/logoPipedrive.svg';
import Yale from './inline/logoYale.svg';

import Container from '../../container';
import { ShadedScrollDownIcon, CloseIcon, SkinnyCheckIcon } from '../../Icons';
import { TimelineMax } from 'gsap';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { baseWhiteButton } from '../../button';
import { Link } from 'gatsby';

import Modal from 'react-modal';

const ScrollDownBox = styled.div`
  display: flex;
  align-items: center;
  margin: 80px 0;

  @media only screen and (max-width: 700px) {
    display: none;
  }

  @media only screen and (min-width: 700px) {
    padding: 0 175px;
    justify-content: flex-end;
  }
`;

const ScrollDownBoxText = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #9ca3b6;
  margin: 0 20px 10px 0;
`;

function scrollToBenefits() {
  const benefitsElement = document.getElementById('benefits');
  benefitsElement.scrollIntoView({ behavior: 'smooth' });
}

const FadeAnimation = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Chat = styled.div`
  animation: ${FadeAnimation} 0.5s;
  position: relative;
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(132, 137, 155, 0.15);
  border-radius: 8px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 700px) {
    padding: 20px 40px;
  }

  @media only screen and (min-width: 700px) {
    padding: 10px 20px;
  }
`;

const ChatText = styled.div`
  margin-left: 10px;
  max-width: 180px;
`;

const ColoredIconHolder = styled.div`
  position: absolute;
  top: -23px;
  right: -23px;
  padding: 6px;
  background: ${(props) => props.background};
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white;
`;

const ChatTexts = [
  {
    text: "What's your pricing information?",
    status: 'question',
  },
  {
    text: 'Perfect, thanks for the fast response!',
    status: 'answer',
  },
  {
    text: 'How to factory reset my device?',
    status: 'question',
  },
  {
    text: 'Great, works like a charm!',
    status: 'answer',
  },
  {
    text: 'Hey, my order is late...',
    status: 'question',
  },
  {
    text: "Awesome, I'll pick it up in 5 min!",
    status: 'answer',
  },
];

const HeroWrap = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
  overflow: hidden;
  margin-bottom: 20px;
  :before {
    background-image: linear-gradient(
      rgba(147, 150, 255, 0.5),
      rgba(62, 115, 215, 0.7)
    );
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  @media only screen and (max-width: 1279px) {
    padding: 120px 20px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 20px;
  }
`;

const HeroContent = styled.div`
  max-width: 780px;
  margin: 0px auto;
  width: 100%;
  position: relative;
  text-align: center;
  z-index: 1;

  @media only screen and (min-width: 1280px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const HeroMainTitle = styled.h1`
  font-size: 58px;
  font-family: 'Gotham-Medium';
  margin: 0 0 30px 0;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    font-size: 44px;
    line-height: 1.2;
    margin-top: 120px;
  }
`;

const HeroMainSubtitle = styled.div`
  font-family: 'Gotham-Medium';
  font-size: 18px;
  color: #ffffff;
  line-height: 31px;

  @media only screen and (min-width: 700px) {
    max-width: 540px;
    margin: 0 auto;
  }
`;

const WatchVideoBtn = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: white;
  margin: 30px 15px 0px 15px;
  padding: 12px 28px;
  cursor: pointer;
`;

const BtnIcon = styled.div`
  margin-right: 12px;
`;

const ScheduleDemoBtn = styled(Link)`
  ${baseWhiteButton}
  margin-top: 40px;
`;

const ContainerLg = styled.div`
  max-width: 1280px;
  margin: 0px auto;
  width: 100%;
`;

const CarouselLogo = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 38px;
  box-sizing: border-box;
  @media only screen and (max-width: 700px) {
    padding: 0px 12px;
  }
`;

const RotatingChat = ({ text, status }) => {
  return (
    <Chat id='chatBox'>
      <Face />
      <ChatText>
        <div id='chatText'>{text}</div>
        {status === 'question' ? (
          <ColoredIconHolder background='#D0DAE1'>
            <CloseIcon />
          </ColoredIconHolder>
        ) : (
          <ColoredIconHolder background='#37D1A9'>
            <SkinnyCheckIcon />
          </ColoredIconHolder>
        )}
      </ChatText>
    </Chat>
  );
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

RotatingChat.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
};

// Modal.setAppElement('#Intro')
const Intro = ({ data }) => {
  const [chatTextIdx, setChatTextIdx] = useState(0);
  const [isModalOpen, setModal] = useState(false);

  function openModal() {
    Modal.setAppElement('#___gatsby');
    trackCustomEvent({
      category: 'Hero video modal',
      action: 'Opened'
    });
    setModal(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    trackCustomEvent({
      category: 'Hero video modal',
      action: 'Closed'
    });
    setModal(false);
  }

  useEffect(() => {
    trackCustomEvent({
      category: 'Hero video modal',
      action: 'Loaded'
    });
  }, [])

  useEffect(() => {
    let interval;
    const tl = new TimelineMax({
      onComplete: () => {
        interval = setInterval(() => {
          let newChatTextIdx = chatTextIdx + 1;
          if (newChatTextIdx > ChatTexts.length - 1) {
            newChatTextIdx = 0;
          }
          setChatTextIdx(newChatTextIdx);
        }, 250);
      },
    });

    tl.to('#agentOnPlatform', 0.5, { opacity: 1, transform: 'scale(1)' })
      .to(
        '#rotatingChatFloaty',
        0.5,
        { opacity: 1, transform: 'scale(1)' },
        '+=0.25',
      )
      .to('#botFace', 0.5, { opacity: 1, transform: 'scale(0.8)' }, '+=0.25');

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <section id='intro'>
      <HeroWrap>
        <HeroContent>
          <HeroMainTitle>
            Give your customers the support they deserve
          </HeroMainTitle>
          <HeroMainSubtitle>
            Reduce your team’s workflow up to 60% with your tailored AI agent,
            and deliver a scalable, exceptional experience
          </HeroMainSubtitle>
          <WatchVideoBtn onClick={openModal}>
            <BtnIcon>
              <Play />
            </BtnIcon>
            Watch Video
          </WatchVideoBtn>
          <Modal
            isOpen={isModalOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.8)',
                zIndex: 9999,
              },
              content: {
                backgroundColor: 'transparent',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: 640 * 1.5,
                maxHeight: 360 * 1.5,
                height: '100%',
                padding: 0,
                border: 0,
              },
            }}
          >
            <iframe
              src='https://player.vimeo.com/video/366013277?byline=0&portrait=0'
              title='Video'
              width='100%'
              height='100%'
              frameBorder='0'
              allow='autoplay; fullscreen'
              allowFullScreen
            ></iframe>
          </Modal>
        </HeroContent>
        <iframe
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: ' translate(-50%, -50%)',
            width: '100vw',
            height: '56.25vw',
            minHeight: '100%',
            minWidth: '177.77vh',
          }}
          src='https://player.vimeo.com/video/442175841?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0'
          title='bgVideo'
          width='100%'
          height='100%'
          frameBorder='0'
          allow='autoplay; fullscreen'
          muted
          allowFullScreen
        ></iframe>
      </HeroWrap>

      <ContainerLg>
        <Carousel
          arrows={false}
          autoPlay
          autoPlaySpeed={5000}
          infinite
          responsive={responsive}
        >
          <CarouselLogo>
            <Anker />
          </CarouselLogo>
          <CarouselLogo>
            <August />
          </CarouselLogo>
          <CarouselLogo>
            <Chamberlain />
          </CarouselLogo>
          <CarouselLogo>
            <Happysocks />
          </CarouselLogo>
          <CarouselLogo>
            <Liftmaster />
          </CarouselLogo>
          <CarouselLogo>
            <Lovehoney />
          </CarouselLogo>
          <CarouselLogo>
            <Pipedrive />
          </CarouselLogo>
          <CarouselLogo>
            <Yale />
          </CarouselLogo>
        </Carousel>
      </ContainerLg>

      <Container>
        <ScrollDownBox>
          <ScrollDownBoxText>Scroll down</ScrollDownBoxText>
          <button onClick={scrollToBenefits}>
            <ShadedScrollDownIcon />
          </button>
        </ScrollDownBox>
      </Container>
    </section>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
