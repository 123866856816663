import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import DeflectTickets from './inline/DeflectTickets.svg';
import InstantAnswer from './inline/InstantAnswer.svg';
import DelightCustomers from './inline/DelightCustomers.svg';

import Container from "../../container";
import { ShadedBenefitsIcon } from "../../Icons";
import { SectionDescription } from "../../common";
import { prepareText } from "../../../utils/helpers";

const BenefitsSection = styled.section`
  margin-top: -120px;
  padding-top: 120px;
`;

const BenefitsContent = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BenefitsTitle = styled.h2`
  color: #30323D;
  margin-bottom: 20px;
`;

const BenefitsSections = styled.div`
  text-align: center;

  @media only screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
  }

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: repeat(3, calc((100% / 3) - 15px));
    grid-column-gap: 20px;
    justify-items: center;
  }
`;

const BenefitsSectionTitle = styled.h3`
  font-weight: bolder;
  margin-bottom: 10px;
  font-size: 24px;
  color: #32325D;
  letter-spacing: 0.22px;
  line-height: 30px;
`;

const BenefitSectionContent = styled.div`
  justify-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 700px) {
    text-align: center;
    padding: 10px;
    justify-content: center;
    margin-bottom: 40px;
  }

  @media only screen and (min-width: 700px) {
    padding: 10px 50px;
    grid-row: 2 / 3;
  }
`;

const BenefitSectionText = styled.div`
  margin-top: 10px;
`;

const BenefitsIcon = styled.div`
  min-height: 150px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media only screen and (max-width: 700px) {
    text-align: center;
    padding: 0 10px;
  }

  @media only screen and (min-width: 700px) {
    grid-row: 1 / 2;
  }
`;

const FadingWhiteSquare = styled.div`
  position: absolute;
  z-index: -3;
  opacity: 0.36;
  transform: rotate(-60deg);
  background: rgba(255,255,255,0.20);
  box-shadow: 80px -80px 100px 0 rgba(10,13,36,0.05);
  border-radius: 174px;
  width: 765px;
  height: 765px;
  left: -200px;
  top: -480px;
`;

const FadingGraySquare = styled.div`
  position: absolute;
  background-image: linear-gradient(-183deg,#a5a9ff0f 15%,#fff 100%);
  transform: rotate(-60deg);
`;

const BigFadingGraySquare = styled(FadingGraySquare)`
  z-index: -4;
  height: 1090px;
  width: 1090px;
  border-radius: 296px;
  left: -220px;
  top: -90px;
`;

const BenefitSection = ({ title, text, background }) => (
  <BenefitSectionContent>
    <BenefitSectionText>
      <BenefitsSectionTitle>{title}</BenefitsSectionTitle>
      <SectionDescription>{prepareText(text)}</SectionDescription>
    </BenefitSectionText>
    {background}
  </BenefitSectionContent>
);

BenefitSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  background: PropTypes.element,
};

const Benefits = ({ data }) => {
  const { title, sections } = data.benefits;
  const { deflect, instant, delight } = sections;

  return (
    <BenefitsSection id="benefits">
      <BenefitsContent>
        <ShadedBenefitsIcon />
        <BenefitsTitle>{title}</BenefitsTitle>
        <BenefitsSections>
          <BenefitsIcon>
            <DeflectTickets />
          </BenefitsIcon>
          <BenefitSection
            title={deflect.title}
            text={deflect.text}
            background={<BigFadingGraySquare />}
          />

          <BenefitsIcon>
            <InstantAnswer />
          </BenefitsIcon>
          <BenefitSection
            title={instant.title}
            text={instant.text}
          />

          <BenefitsIcon>
            <DelightCustomers />
          </BenefitsIcon>
          <BenefitSection
            title={delight.title}
            text={delight.text}
            background={<FadingWhiteSquare />}
          />
        </BenefitsSections>
      </BenefitsContent>
    </BenefitsSection>
  );
};

Benefits.propTypes = {
  data: PropTypes.object,
};

export default Benefits;
