import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Learn from "./inline/Learn.svg";
import Relax from "./inline/Relax.svg";
import AgentOnline from "./inline/AgentOnline.svg";
import Arrow from "./inline/Arrow.svg";

import Container from "../../container";
import { ShadedWorkFlowIcon } from "../../Icons";
import { SectionDescription } from "../../common";
import { prepareText } from "../../../utils/helpers";

const ArrowImage = styled.div`
  margin: 30px 0 50px;

  ${props => props.rotate && css`
    transform: rotate(90deg);
  `}

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const WorkflowImageHolder = styled.div`
  text-align: center;
`;

const WorkflowContent = styled(Container)`
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #9292C822;

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
`;

const WorkflowHeader = styled.div`
  text-align: center;
`;

const WorkflowTitle = styled.h2`
  margin-bottom: 40px;
`;

const WorkflowSections = styled.div`
  text-align: center;

  @media only screen and (max-width: 700px) {
  }

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: repeat(3, calc((100% / 3) - 15px));
    grid-column-gap: 20px;
    justify-items: center;
  }
`;

const WorkflowSectionContent = styled.div`
  position: relative;

  @media only screen and (max-width: 700px) {
    padding: 10px 0;
  }

  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    grid-row: 2 / 3;

    ${props =>
      props.topPadding &&
      css`
        padding-top: ${props.topPadding}px;
      `}
  }
`;

const WorkflowSectionTitle = styled.h3`
  padding: 0 10px;
  font-weight: 600;
`;

const WorkflowSectionText = styled(SectionDescription)`
  padding: 0 10px;

  @media only screen and (min-width: 700px) {
    width: 260px;
  }
`;

const IconWrapper = styled.div`
`;

const MiniFadingGraySquare = styled.div`
  position: absolute;
  background-image: linear-gradient(-183deg,#a5a9ff0f 15%,#fff 100%);
  transform: rotate(-60deg);
  z-index: -2;
  width: 895px;
  height: 895px;
  border-radius: 264px;
  left: -88px;
  top: -500px;
`;

const WorkflowSection = ({ icon, title, text, topPadding, background }) => (
  <WorkflowSectionContent topPadding={topPadding}>
    <IconWrapper>{icon}</IconWrapper>
    <div>
      <WorkflowSectionTitle>{title}</WorkflowSectionTitle>
      <WorkflowSectionText>{prepareText(text)}</WorkflowSectionText>
    </div>
    {background}
  </WorkflowSectionContent>
);

WorkflowSection.propTypes = {
  icon: PropTypes.element,
  background: PropTypes.element,
  topPadding: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  darken: PropTypes.bool,
};

const Workflow = ({ data }) => {
  const { title, sections } = data.workflow;
  const { history, augmentation, presence } = sections;

  return (
    <section id="workflow">
      <WorkflowContent>
        <WorkflowHeader>
          <ShadedWorkFlowIcon />
          <WorkflowTitle>{title}</WorkflowTitle>
        </WorkflowHeader>
        <WorkflowSections>
          <WorkflowSection
            icon={
              <WorkflowImageHolder>
                <ArrowImage>
                  <Arrow />
                </ArrowImage>
                <Learn width="100%" />
              </WorkflowImageHolder>
            }
            topPadding={70}
            title={history.title}
            text={history.text}
            background={<MiniFadingGraySquare />}
          />
          <WorkflowSection
            icon={<Relax width="100%" />}
            topPadding={10}
            title={augmentation.title}
            text={augmentation.text}
          />
          <WorkflowSection
            icon={
              <WorkflowImageHolder>
                <ArrowImage rotate={1}>
                  <Arrow />
                </ArrowImage>
                <AgentOnline width="100%" />
              </WorkflowImageHolder>
            }
            topPadding={75}
            title={presence.title}
            text={presence.text}
          />
        </WorkflowSections>
      </WorkflowContent>
    </section>
  );
};

Workflow.propTypes = {
  data: PropTypes.object,
};

export default Workflow;
