import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";

import msg91 from "../../../assets/logos/msg91.png";
import august from "../../../assets/logos/august.png";
import ycombinator from "../../../assets/logos/ycombinator.png";
import yale from "../../../assets/logos/yale.png";
import lovehoney from "../../../assets/logos/lovehoney.png";
import anker from "../../../assets/logos/anker.png";

import MonikaAvatar from "../../../assets/avatars/Monika.png";
import DavidAvatar from "../../../assets/avatars/David.png";
import AkeshAvatar from "../../../assets/avatars/Akesh.png";
import EricAvatar from "../../../assets/avatars/Eric.png";

import QuoteBackground from "../../../assets/goodcompany/QuoteBackground.svg";

import Container from "../../container";
import { ShadedGoodCompanyIcon, RightArrowIcon } from "../../Icons";
import { SectionDescription } from "../../common";
import { basePurpleButton } from "../../button";
import { Link } from "gatsby";

const AvatarMapping = {
  "Monika Aufdermauer": MonikaAvatar,
  "David Benivegna": DavidAvatar,
  "Akesh Joshi": AkeshAvatar,
  "Eric Ly": EricAvatar,
};

const GoodCompanyContent = styled(Container)`
  position: relative;
  display: flex;

  /* used for allowing the purple background to take the entire width space */
  padding: 0px;

  @media only screen and (max-width: 700px) {
    text-align: center;
    flex-direction: column;
  }

  @media only screen and (min-width: 700px) {
    flex-direction: row;
  }
`;

const GoodCompanyLeftSide = styled.div`
  flex: 1;
  padding: 0 20px;
`;

const GoodCompanyTitle = styled.h2`
  @media only screen and (min-width: 700px) {
    width: 400px;
    margin-bottom: 20px;
  }
`;

const Logos = styled.div`
  @media only screen and (max-width: 700px) {
    margin: 30px 0px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: 700px) {
    margin: 20px 50px 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const LogoImageHolder = styled.div`
  display: inline-flex;
  margin: 20px;
  height: 50px;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 700px) {
    justify-content: flex-start;
  }
`;

const LogoImage = styled.img`
  max-width: 150px;
`;

const GoodCompanyQuoteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  position: relative;

  @media only screen and (max-width: 700px) {
    background: url(${QuoteBackground}) -150px 50px / 650px 650px no-repeat border-box;
    height: 750px;
  }

  @media only screen and (min-width: 700px) {
    background: url(${QuoteBackground}) 0px 0px / 100% 100% no-repeat border-box;
  }
`;

const GoodCompanyQuoteContent = styled.div`
  color: white;
  position: absolute;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 700px) {
    padding: 0 30px;
    height: 355px;
  }

  @media only screen and (min-width: 700px) {
    left: 30%;
    bottom: 25%;
    left: 22%;
    width: 60%;
    height: 50%;
  }
`;

const GoodCompanyButtonContainer = styled.button`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 5px 20px 130px rgba(10, 13, 36, 1);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: rotate(45deg);

  @media only screen and (max-width: 700px) {
    bottom: -130px;
    right: 70px;
  }

  @media only screen and (min-width: 700px) {
    bottom: -25%;
    right: -5%;
  }

  &:hover {
    transform: rotate(45deg);
  }
`;

const GoodCompanyReorient = styled.div`
  transform: rotate(-45deg);
`;

const FadeAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const GoodCompanyQuoteText = styled(SectionDescription)`
  color: white;
  font-size: 16px;
  letter-spacing: 0.13px;
  line-height: 30px;
  animation: ${FadeAnimation} 1s;
  width: 100%;
`;

const GoodCompanyQuoteAuthor = styled.div`
  font-weight: bolder;
  font-size: 14px;
  line-height: 30px;
  animation: ${FadeAnimation} 1s;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Button = styled(Link)`
  ${basePurpleButton}
`;

const Circles = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  left: 10px;
  bottom: 0;
`;

const SmallCircle = styled.div`
  width: 10px;
  height: 10px;
  background: rgba(157, 164, 183, 0.7);
  border-radius: 10px;
  margin: 10px;
  transition: transform 0.2s ease-in-out;

  ${props =>
    props.chosen &&
    css`
      background: #7485e6;
      box-shadow: 0 10px 50px 0 #768af1;
      border-radius: 20px;
      transform: scale(1.5);
    `}
`;

const AvatarImage = styled.img`
  max-width: 50px;
  max-height: 50px;
  margin-right: 20px;
`;

function getNextQuote(quoteIndex, quotes) {
  let nextQuoteIndex = quoteIndex + 1;
  if (nextQuoteIndex > quotes.length - 1) {
    // loop back
    nextQuoteIndex = 0;
  }
  return nextQuoteIndex;
}

const GoodCompany = ({ data }) => {
  const [quoteIndex, setQuoteIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex(getNextQuote(quoteIndex, quotes));
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  });

  const { title, quotes } = data.company;

  const logos = [august, yale, ycombinator, msg91, lovehoney, anker];

  return (
    <GoodCompanyContent>
      <GoodCompanyLeftSide>
        <ShadedGoodCompanyIcon />
        <GoodCompanyTitle>{title}</GoodCompanyTitle>
        <Logos>
          {logos.map(l => (
            <LogoImageHolder key={l}>
              <LogoImage src={l} />
            </LogoImageHolder>
          ))}
        </Logos>
        <Button to="/resources">View Case Studies</Button>
      </GoodCompanyLeftSide>
      <GoodCompanyQuoteContainer>
        {quotes[quoteIndex] && (
          <GoodCompanyQuoteContent>
            <GoodCompanyQuoteText key={"text_" + quoteIndex}>
              {quotes[quoteIndex].text}
            </GoodCompanyQuoteText>
            <GoodCompanyQuoteAuthor key={"author_" + quoteIndex}>
              <AvatarImage src={AvatarMapping[quotes[quoteIndex].name]} />
              <div>
                <div>{quotes[quoteIndex].name}</div>
                <div>{quotes[quoteIndex].title}</div>
                <div>{quotes[quoteIndex].company}</div>
              </div>
            </GoodCompanyQuoteAuthor>
            <GoodCompanyButtonContainer
              onClick={() => {
                setQuoteIndex(getNextQuote(quoteIndex, quotes));
              }}
            >
              <GoodCompanyReorient>
                <RightArrowIcon />
              </GoodCompanyReorient>
            </GoodCompanyButtonContainer>
          </GoodCompanyQuoteContent>
        )}
        <Circles>
          {quotes.map((q, i) => (
            <SmallCircle key={q.name} chosen={i === quoteIndex} />
          ))}
        </Circles>
      </GoodCompanyQuoteContainer>
    </GoodCompanyContent>
  );
};

GoodCompany.propTypes = {
  data: PropTypes.object,
};

export default GoodCompany;
