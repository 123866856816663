import React from 'react';

import data from '../data/home.yaml';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Intro from '../components/pages/home/intro';
import Benefits from '../components/pages/home/benefits';
import Workflow from '../components/pages/home/workflow';
import GoodCompany from '../components/pages/home/goodcompany';
import Augment from '../components/pages/home/augment';
import Announcement from '../components/pages/home/announcement';

const IndexPage = () => (
  <Layout hasHome whiteMiddle whiteCorner whiteLogo whiteMobile>
    <SEO
      title='AI Support Agent for Your Business | Empower Customer Service with Artificial Intelligence.'
      metaTitle='AI support agent for your business that actually works'
      keywords={[
        `Achieve Higher Customer Satisfaction for 24/7 by the Artificial Intelligence Agent that Actually Works from Percept AI`,
      ]}
    />
    <Announcement />
    <Intro data={data} />
    <Benefits data={data} />
    <GoodCompany data={data} />
    <Workflow data={data} />
    <Augment data={data} />
  </Layout>
);

export default IndexPage;
