import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import shopify from "../../../assets/logos/shopify.png";
import desk from "../../../assets/logos/desk.png";
import intercom from "../../../assets/logos/intercom.png";
import zendesk from "../../../assets/logos/zendesk.png";
import kustomer from "../../../assets/logos/kustomer.png";
import liveperson from "../../../assets/logos/liveperson.png";
import ujet from "../../../assets/logos/ujet.png";
import oracle from "../../../assets/logos/oracle.png";
import messenger from "../../../assets/logos/messenger.png";
import freshdesk from "../../../assets/logos/freshdesk.png";
import salesforce from "../../../assets/logos/salesforce.png";
import livechat from "../../../assets/logos/livechat.png";

import Container from "../../container";
import { ShadedAugmentIcon } from "../../Icons";
import { SectionDescription } from "../../common";
import { prepareText } from "../../../utils/helpers";

const AugmentContent = styled(Container)`
  position: relative;

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
`;

const AugmentFlexContainer = styled.div`
  @media only screen and (min-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AugmentDescription = styled.div`
  flex: 1 0 0;

  @media only screen and (min-width: 700px) {
    margin-right: 90px;
  }
`;

const AugmentText = styled.div`
  flex: 1 0 0;
`;

const AugmentTitle = styled.h2`
  color: #30323D;
`;

const AugmentPlatforms = styled.div`
  @media only screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 100px;
    justify-items: center;
    align-items: center;
  }

  @media only screen and (min-width: 700px) {
    flex: 1 0 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100px;
    justify-items: center;
    align-items: center;
  }
`;

const AugmentImage = styled.img`
  max-width: 100%;
`;

const Augment = ({ data }) => {
  const { title, subtext } = data.augmentation;

  const platforms = [
    shopify, desk, intercom, zendesk, kustomer, liveperson, ujet, oracle, messenger,
    freshdesk, salesforce, livechat
  ];

  return (
    <section id="augment">
      <AugmentContent>
        <AugmentFlexContainer>
          <AugmentDescription>
            <ShadedAugmentIcon />
            <AugmentText>
              <AugmentTitle>{title}</AugmentTitle>
              <SectionDescription>{prepareText(subtext)}</SectionDescription>
            </AugmentText>
          </AugmentDescription>
          <AugmentPlatforms>
            {
              platforms.map(p =>
                <AugmentImage key={p} src={p} alt='Related augmentation platform' />
              )
            }
          </AugmentPlatforms>
        </AugmentFlexContainer>
      </AugmentContent>
    </section>
  );
};

Augment.propTypes = {
  data: PropTypes.object,
};

export default Augment;
