import React from "react";
import styled, { css } from "styled-components";
import AnnouncementSrc from "../../../assets/announcement/announcement.png";

const AnnouncementContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh + 120px);
  margin-top: -120px;
  background: linear-gradient(to bottom right, #7589F0, #9396FF);
  padding-top: 150px;
  padding-bottom: 150px;
  box-sizing: border-box;
`;

const AnnouncementImageHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

const AnnouncementImage = styled.img`
  width: 80%;
  @media only screen and (min-width: 767px) {
    max-width: 780px;
  }
`;

const AnnouncementHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 780px;
  margin: 50px auto;
`;

const AnnouncementText = styled.h1`
  font-size: 58px;
  font-family: 'Gotham-Medium';
  color: #ffffff;
  text-align: center;
  padding: 10px;

  @media only screen and (max-width: 767px) {
    font-size: 44px;
    line-height: 1.2;
  }
`;

const AnnouncementLink =  styled.a`
  font-family: 'Gotham-Medium';
  font-size: 18px;
  color: #ffffff;
  line-height: 31px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
`;

const Announcement = ({}) => {
  return (
    <section id="announcement">
      <AnnouncementContent>
        <AnnouncementImageHolder>
          <AnnouncementImage src={AnnouncementSrc} />
        </AnnouncementImageHolder>
        <AnnouncementHolder>
          <AnnouncementText>
            Percept.AI has been acquired by Atlassian.
          </AnnouncementText>
          <AnnouncementLink href="https://atlassian.com/blog/announcements/atlassian-acquires-percept">
            Learn More.
          </AnnouncementLink>
        </AnnouncementHolder>
      </AnnouncementContent>
    </section>
  );
};

export default Announcement;
